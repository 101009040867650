import { storeToRefs } from 'pinia'; // import storeToRefs helper hook from pinia
import { useAuthStore } from '~/stores/auth/auth'; // import the auth store
import { useFlashMessageStore } from '~/stores/flashMessage';


export default defineNuxtRouteMiddleware((to) => {
    
    const authStore = useAuthStore(); // get the auth store
    const flashMessageStore = useFlashMessageStore(); // get the flash message store
    const { authenticated } = storeToRefs(useAuthStore()); // make authenticated state reactive
    
    // console.log("authenticated: ", authenticated)
    console.log("authenticated.value: ", authenticated.value)
    


    // if token exists and url is /login redirect to homepage
    if (authenticated.value && to?.name === 'index') {
        console.log("has been login");
        // console.log("to profile");
        // return navigateTo('/profile');
    }

    // if token doesn't exist redirect to log in
    if (!authenticated.value && to?.name !== 'index') {
        console.log("need to login");
        console.log("to?.name", to?.name)
        // abortNavigation();
        // usePVToastService().add({severity:'error', summary: 'Error', detail: 'You need to log in to access this page.',  group: 'br', life: 5000});
        // if (process.client) {
        //     console.log("client")
        //     // const toast_error = useCookie('toast_error')
        //     // toast_error.value =  'You need to log in to access this page';
        //     // flashMessageStore.setMessage('You need to log in to access this page', 'error');
        //     console.log("You need to log in to access this page', 'error'");
        // }
        // if (process.server) {
        //     console.log("server")
        // }
        // flashMessageStore.setMessage('You need to log in to access this page', 'error');
        // console.log("flashMessageStore.state.message: ", flashMessageStore.message);
        // console.log("flashMessageStore.state.message_type: ", flashMessageStore.message_type);
        // return navigateTo('/');
        return navigateTo('/?error=You need to log in to access this page.');
    }
});