export default defineNuxtRouteMiddleware(to => {
    // console.log("process.server", process.server)
    // console.log("process.client", process.client)
    // const nuxtApp = useNuxtApp()
    // console.log("nuxtApp.isHydrating", nuxtApp.isHydrating)
    // console.log("nuxtApp.payload.serverRendered", nuxtApp.payload.serverRendered)
    // // skip middleware on server
    // if (process.server) return
    // // skip middleware on client side entirely
    // if (process.client) return
    // // or only skip middleware on initial client load
    
    
    // if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return
  })