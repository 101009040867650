import { default as loginIr6aK037uMMeta } from "/home/api/app/pages/auth/login.vue?macro=true";
import { default as dashboardcy2mdyM6gSMeta } from "/home/api/app/pages/dashboard.vue?macro=true";
import { default as indexM70bW0TaKGMeta } from "/home/api/app/pages/index.vue?macro=true";
import { default as loginI6fZOO0cw7Meta } from "/home/api/app/pages/login.vue?macro=true";
import { default as memberscSStoVRKq2Meta } from "/home/api/app/pages/members.vue?macro=true";
import { default as memberslYyZQdDXKVMeta } from "/home/api/app/pages/mlm/members.vue?macro=true";
import { default as order_45transactionsyifKoyQRPhMeta } from "/home/api/app/pages/mlm/order-transactions.vue?macro=true";
import { default as _91id_93F3DwPl8dXFMeta } from "/home/api/app/pages/mlm/orders/[id].vue?macro=true";
import { default as indexnk8I6pGwhJMeta } from "/home/api/app/pages/mlm/orders/index.vue?macro=true";
import { default as productsUVoUInjlHuMeta } from "/home/api/app/pages/mlm/products.vue?macro=true";
import { default as rebate_45categorieshgG2SUmzyxMeta } from "/home/api/app/pages/mlm/rebate-categories.vue?macro=true";
import { default as user_45rebate_45historyLrBEAfo0f7Meta } from "/home/api/app/pages/mlm/user-rebate-history.vue?macro=true";
import { default as _91id_93p3H4pXwLSmMeta } from "/home/api/app/pages/posts/[id].vue?macro=true";
import { default as testhhC6ytnY4wMeta } from "/home/api/app/pages/test.ts?macro=true";
import { default as test2CoFurl3QvlMeta } from "/home/api/app/pages/test2.tsx?macro=true";
import { default as user_45loyaltySNyvhLOUbPMeta } from "/home/api/app/pages/user-loyalty.vue?macro=true";
import { default as user_45vouchersGtPBO1cru3Meta } from "/home/api/app/pages/user-vouchers.vue?macro=true";
import { default as _91id_93ZB370rLFRMMeta } from "/home/api/app/pages/users-[group]/[id].vue?macro=true";
export default [
  {
    name: loginIr6aK037uMMeta?.name ?? "auth-login",
    path: loginIr6aK037uMMeta?.path ?? "/auth/login",
    meta: loginIr6aK037uMMeta || {},
    alias: loginIr6aK037uMMeta?.alias || [],
    redirect: loginIr6aK037uMMeta?.redirect,
    component: () => import("/home/api/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: dashboardcy2mdyM6gSMeta?.name ?? "dashboard",
    path: dashboardcy2mdyM6gSMeta?.path ?? "/dashboard",
    meta: dashboardcy2mdyM6gSMeta || {},
    alias: dashboardcy2mdyM6gSMeta?.alias || [],
    redirect: dashboardcy2mdyM6gSMeta?.redirect,
    component: () => import("/home/api/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexM70bW0TaKGMeta?.name ?? "index",
    path: indexM70bW0TaKGMeta?.path ?? "/",
    meta: indexM70bW0TaKGMeta || {},
    alias: indexM70bW0TaKGMeta?.alias || [],
    redirect: indexM70bW0TaKGMeta?.redirect,
    component: () => import("/home/api/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginI6fZOO0cw7Meta?.name ?? "login",
    path: loginI6fZOO0cw7Meta?.path ?? "/login",
    meta: loginI6fZOO0cw7Meta || {},
    alias: loginI6fZOO0cw7Meta?.alias || [],
    redirect: loginI6fZOO0cw7Meta?.redirect,
    component: () => import("/home/api/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: memberscSStoVRKq2Meta?.name ?? "members",
    path: memberscSStoVRKq2Meta?.path ?? "/members",
    meta: memberscSStoVRKq2Meta || {},
    alias: memberscSStoVRKq2Meta?.alias || [],
    redirect: memberscSStoVRKq2Meta?.redirect,
    component: () => import("/home/api/app/pages/members.vue").then(m => m.default || m)
  },
  {
    name: memberslYyZQdDXKVMeta?.name ?? "mlm-members",
    path: memberslYyZQdDXKVMeta?.path ?? "/mlm/members",
    meta: memberslYyZQdDXKVMeta || {},
    alias: memberslYyZQdDXKVMeta?.alias || [],
    redirect: memberslYyZQdDXKVMeta?.redirect,
    component: () => import("/home/api/app/pages/mlm/members.vue").then(m => m.default || m)
  },
  {
    name: order_45transactionsyifKoyQRPhMeta?.name ?? "mlm-order-transactions",
    path: order_45transactionsyifKoyQRPhMeta?.path ?? "/mlm/order-transactions",
    meta: order_45transactionsyifKoyQRPhMeta || {},
    alias: order_45transactionsyifKoyQRPhMeta?.alias || [],
    redirect: order_45transactionsyifKoyQRPhMeta?.redirect,
    component: () => import("/home/api/app/pages/mlm/order-transactions.vue").then(m => m.default || m)
  },
  {
    name: _91id_93F3DwPl8dXFMeta?.name ?? "mlm-orders-id",
    path: _91id_93F3DwPl8dXFMeta?.path ?? "/mlm/orders/:id()",
    meta: _91id_93F3DwPl8dXFMeta || {},
    alias: _91id_93F3DwPl8dXFMeta?.alias || [],
    redirect: _91id_93F3DwPl8dXFMeta?.redirect,
    component: () => import("/home/api/app/pages/mlm/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnk8I6pGwhJMeta?.name ?? "mlm-orders",
    path: indexnk8I6pGwhJMeta?.path ?? "/mlm/orders",
    meta: indexnk8I6pGwhJMeta || {},
    alias: indexnk8I6pGwhJMeta?.alias || [],
    redirect: indexnk8I6pGwhJMeta?.redirect,
    component: () => import("/home/api/app/pages/mlm/orders/index.vue").then(m => m.default || m)
  },
  {
    name: productsUVoUInjlHuMeta?.name ?? "mlm-products",
    path: productsUVoUInjlHuMeta?.path ?? "/mlm/products",
    meta: productsUVoUInjlHuMeta || {},
    alias: productsUVoUInjlHuMeta?.alias || [],
    redirect: productsUVoUInjlHuMeta?.redirect,
    component: () => import("/home/api/app/pages/mlm/products.vue").then(m => m.default || m)
  },
  {
    name: rebate_45categorieshgG2SUmzyxMeta?.name ?? "mlm-rebate-categories",
    path: rebate_45categorieshgG2SUmzyxMeta?.path ?? "/mlm/rebate-categories",
    meta: rebate_45categorieshgG2SUmzyxMeta || {},
    alias: rebate_45categorieshgG2SUmzyxMeta?.alias || [],
    redirect: rebate_45categorieshgG2SUmzyxMeta?.redirect,
    component: () => import("/home/api/app/pages/mlm/rebate-categories.vue").then(m => m.default || m)
  },
  {
    name: user_45rebate_45historyLrBEAfo0f7Meta?.name ?? "mlm-user-rebate-history",
    path: user_45rebate_45historyLrBEAfo0f7Meta?.path ?? "/mlm/user-rebate-history",
    meta: user_45rebate_45historyLrBEAfo0f7Meta || {},
    alias: user_45rebate_45historyLrBEAfo0f7Meta?.alias || [],
    redirect: user_45rebate_45historyLrBEAfo0f7Meta?.redirect,
    component: () => import("/home/api/app/pages/mlm/user-rebate-history.vue").then(m => m.default || m)
  },
  {
    name: _91id_93p3H4pXwLSmMeta?.name ?? "posts-id",
    path: _91id_93p3H4pXwLSmMeta?.path ?? "/posts/:id()",
    meta: _91id_93p3H4pXwLSmMeta || {},
    alias: _91id_93p3H4pXwLSmMeta?.alias || [],
    redirect: _91id_93p3H4pXwLSmMeta?.redirect,
    component: () => import("/home/api/app/pages/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: testhhC6ytnY4wMeta?.name ?? "test",
    path: testhhC6ytnY4wMeta?.path ?? "/test",
    meta: testhhC6ytnY4wMeta || {},
    alias: testhhC6ytnY4wMeta?.alias || [],
    redirect: testhhC6ytnY4wMeta?.redirect,
    component: () => import("/home/api/app/pages/test.ts").then(m => m.default || m)
  },
  {
    name: test2CoFurl3QvlMeta?.name ?? "test2",
    path: test2CoFurl3QvlMeta?.path ?? "/test2",
    meta: test2CoFurl3QvlMeta || {},
    alias: test2CoFurl3QvlMeta?.alias || [],
    redirect: test2CoFurl3QvlMeta?.redirect,
    component: () => import("/home/api/app/pages/test2.tsx").then(m => m.default || m)
  },
  {
    name: user_45loyaltySNyvhLOUbPMeta?.name ?? "user-loyalty",
    path: user_45loyaltySNyvhLOUbPMeta?.path ?? "/user-loyalty",
    meta: user_45loyaltySNyvhLOUbPMeta || {},
    alias: user_45loyaltySNyvhLOUbPMeta?.alias || [],
    redirect: user_45loyaltySNyvhLOUbPMeta?.redirect,
    component: () => import("/home/api/app/pages/user-loyalty.vue").then(m => m.default || m)
  },
  {
    name: user_45vouchersGtPBO1cru3Meta?.name ?? "user-vouchers",
    path: user_45vouchersGtPBO1cru3Meta?.path ?? "/user-vouchers",
    meta: user_45vouchersGtPBO1cru3Meta || {},
    alias: user_45vouchersGtPBO1cru3Meta?.alias || [],
    redirect: user_45vouchersGtPBO1cru3Meta?.redirect,
    component: () => import("/home/api/app/pages/user-vouchers.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZB370rLFRMMeta?.name ?? "users-group-id",
    path: _91id_93ZB370rLFRMMeta?.path ?? "/users-:group()/:id()",
    meta: _91id_93ZB370rLFRMMeta || {},
    alias: _91id_93ZB370rLFRMMeta?.alias || [],
    redirect: _91id_93ZB370rLFRMMeta?.redirect,
    component: () => import("/home/api/app/pages/users-[group]/[id].vue").then(m => m.default || m)
  }
]