import validate from "/home/api/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/api/app/middleware/auth.global.ts";
import log_45global from "/home/api/app/middleware/log.global.ts";
import manifest_45route_45rule from "/home/api/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  log_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/api/app/middleware/auth.ts"),
  "my-middleware": () => import("/home/api/app/middleware/my-middleware.ts")
}