import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';


interface UserPayloadInterface {
  username: string;
  password: string;
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authenticated: false,
    loading: false,
  }),
  actions: {
    async authenticateUser({ username, password }: UserPayloadInterface) {
      const ui_blocked = useState('ui_blocked')
      ui_blocked.value = true;
  
      // useFetch from nuxt 3
      const { data, pending }: any = await useFetch('/api/cms/auth/login', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: {
          username,
          password,
        },
      });

      this.loading = pending;

      setTimeout(() => {
        ui_blocked.value = false;
      }, 1000);
      

      if (data.value) {
        ui_blocked.value = false;
        if (data.value?.status === 'OK') {

          this.authenticated = true; // set authenticated state value to true
          
          usePVToastService().add({ severity: 'success', summary: 'Success Message', detail: 'You have success login.', group: 'br', life: 5000 });
          
        } else if (data.value?.status === 'FAIL') {
          this.authenticated = false;
          // const toastService = usePVToastService();
          usePVToastService().add({severity:'error', summary: 'Error', detail: data.value?.message,  group: 'br', life: 5000});
        } 
      }
    },
    logUserOut() {
      this.authenticated = false; // set authenticated  state value to false
    },
  },
  persist: true,
});