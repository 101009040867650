export default {
  "app-config": () => import("/home/api/app/layouts/AppConfig.vue").then(m => m.default || m),
  "app-footer": () => import("/home/api/app/layouts/AppFooter.vue").then(m => m.default || m),
  "app-layout": () => import("/home/api/app/layouts/AppLayout.vue").then(m => m.default || m),
  "app-menu": () => import("/home/api/app/layouts/AppMenu.vue").then(m => m.default || m),
  "app-menu-item": () => import("/home/api/app/layouts/AppMenuItem.vue").then(m => m.default || m),
  "app-sidebar": () => import("/home/api/app/layouts/AppSidebar.vue").then(m => m.default || m),
  "app-topbar": () => import("/home/api/app/layouts/AppTopbar.vue").then(m => m.default || m),
  "composables-layout": () => import("/home/api/app/layouts/composables/layout.ts").then(m => m.default || m),
  custom: () => import("/home/api/app/layouts/custom.vue").then(m => m.default || m),
  "default-backup": () => import("/home/api/app/layouts/default.backup.vue").then(m => m.default || m),
  default: () => import("/home/api/app/layouts/default.vue").then(m => m.default || m),
  landing: () => import("/home/api/app/layouts/landing.vue").then(m => m.default || m)
}