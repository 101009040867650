<style>
@import url("~/assets/css/main.css");
/* @import url('primevue/resources/themes/nova/theme.css'); */
</style>
<script setup lang="ts">

</script>

<template>
  
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>

<style scoped  lang="scss"></style>

