import { defineStore } from 'pinia'

export const useFlashMessageStore = defineStore({
  id: 'flash-message',
  state: () => {
    return {
      message: "",
      message_type: "",
    }
  },
  actions: {
    setMessage(message: string, message_type: string) {
        this.message = message;
        this.message_type = message_type;
    },
    clearMessage() {
        this.message = "";
        this.message_type = "";
    },
    flashMessage() {
        if (this.message != ""){
            usePVToastService().add({ severity: this.message_type, summary: this.message_type + ' message', detail: this.message, life: 5000 });
            this.clearMessage();
        }
    }
    
  },
  persist: true,
})

